/*
*
* Posts
* --------------------------------------------------
*/


.post-blog{

  &__title{

  }
}

.post-default {
  .post-media {
    img { width: 100%; }
  }

  * + .post-title { margin-top: 20px;}
  * + .post-meta { margin-top: 12px;}
  * + .post-content { margin-top: 15px;}
  * + .post-link { margin-top: 12px;}

  @media (min-width: $screen-xl) {
    * + .post-title { margin-top: 31px;}
    * + .post-meta { margin-top: 16px;}
    * + .post-content { margin-top: 17px;}
    * + .post-link { margin-top: 19px;}
  }
}

.post-link {
  position: relative;
  display: inline-block;
  padding-left: 37px;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;

  &:before {
    content: "\f14f";
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    font-family: $mdi;
    font-size: 24px;
  }
}

.post-project {
  .project-body {
    display: flex;
    flex-direction: column;
  }

  .project-caption { flex-grow: 1; }
  .project-title { font-weight: 700; }
  .project-date { color: $gray-lighter; }

  .comment, .author {
    &:before {
      padding-right: 7px;
      font-family: $mdi;
      vertical-align: middle;
      color: $secondary;
    }
  }

  .comment {
    color: $gray-darker;

    &:before {
      content: "\f270";
      font-size: 17px;
    }
  }

  .author {
    margin-left: 29px;

    &:before {
      content: "\f103";
      font-size: 20px;
    }
  }

  * + .project-title { margin-top: 15px; }
  * + .project-meta { margin-top: 10px; }

  @media (min-width: $screen-xs) {
    .project-body {
      flex-basis: 100%;
    }
  }
}

.post-minimal {
  .post-title { font-weight: 700; }
  * + .post-meta { margin-top: 5px; }

  @media (min-width: $screen-lg) {
    * + .post-meta { margin-top: 13px; }
  }
}

.post-classic {
  * + .post-content { margin-top: 15px;}
  * + .post-meta { margin-top: 10px;}
  * + .post-description { margin-top: 10px;}
  * + .post-link { margin-top: 19px;}
  & + & { margin-top: 40px; }

  @media (min-width: $screen-lg) {
    * + .post-content { margin-top: 39px;}
    & + & { margin-top: 92px; }
  }

  @media (min-width: $screen-xl) {
    * + .post-meta { margin-top: 20px;}
    * + .post-description { margin-top: 16px;}
  }
}

.post-masonry {
  background: $white;
  transition: .3s;

  .post-media {
    position: relative;

    img { width: 100%; }

    .tag {
      position: absolute;
      top: 15px;
      left: 15px;
      display: inline-block;
      padding: 3px 15px;
      font-style: italic;
      color: $gray-darker;
      background: $secondary;
      transition: .3s;

      &:hover {
        color: $white;
        background: $primary;
      }
    }
  }

  .post-content {
    padding: 30px;

    .icon { padding-right: 7px; }
    .time { font-style: italic; }
    .post-title { font-weight: 700; }

    * + .post-title { margin-top: 18px; }
    * + .post-exeption { margin-top: 20px; }
  }

  & + & { margin-top: 40px; }
  &:hover { box-shadow: $shadow-area-md; }

  @media (min-width: $screen-lg) {
    .post-media {
      .tag {
        top: 30px;
        left: 30px;
      }
    }
  }

  @media (min-width: $screen-xl) {
    & + & { margin-top: 72px; }
  }
}
.post-short {
  .time {
    font-style: italic;
    color: $gray-lighter;
  }

  * + .post-body { margin-top: 8px; }

  & + & { margin-top: 29px; }
}

// Post Modern
// --------------------------------------------------
$post-width: 640px;

.post-modern {
  margin-left: auto;
  margin-right: auto;
  background-color: $white;

  .post-inset,
  .post-content {
    padding: $post-modern-content-padding;
  }

  .post-body { margin-top: 18px; }

  .post-inset {
    padding-bottom: 0;
  }

  .post-meta {
    margin-top: 14px;
    color: $gray-lighter;
    font-style: italic;
  }

  @media (min-width: $screen-xl) { max-width: $post-width; }
}

// Post Timeline (horizontal "divider")
// --------------------------------------------------

.post-timeline {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: -33px;
    left: auto;
    right: 0;
    height: 1px;
    width: 100%;
    background: $post-timeline-border-color;
  }

  & + .post-timeline {
    margin-top: 66px;

    @media (min-width: $screen-sm-min) {
      margin-top: 40px;
    }
  }

  @media (min-width: $screen-sm-min) {
    &:first-child {
      position: relative;
      padding-top: 40px;

      &:before {
        content: '';
        position: absolute;
        top: -1px;
        right: 0;
        width: 86.5%;
        height: 1px;
        background: $post-timeline-border-color;
      }

      &:after {
        content: '';
        position: absolute;
        top: -1px;
        left: 14px;
        width: 47px;
        height: 1px;
        background: $post-timeline-border-color;
      }
    }

    & + .post-timeline {
      border-top: 1px solid $post-timeline-border-color;
      padding-top: 50px;
    }

    & + .post-timeline {
      border: none;

      &:before {
        top: 0;
        width: 86.5%;
      }
    }
  }

  @media (min-width: $screen-sm-min) {
    &:before {
      top: -25px;
    }
  }
}

// delete first blog divider
.blog-archive-year:first-child {
  .post-timeline:first-child:before,
  .post-timeline:first-child:after {
    display: none;
  }

  .post-timeline:first-child {
    padding-top: 0;
  }
}

// Post Modern Timeline
// --------------------------------------------------

.post-modern-timeline {
  position: relative;

  .post-author {
    margin-top: 0;
    font-style: italic;
    color: $gray-darker;
    padding: 20px;

    > * { display: block; }
  }

  .post-author-img,
  .post-author-name {
    display: block;
    font-style: normal;
    margin-top: 10px;
  }

  & + .post-modern-timeline {
    margin-top: 60px;
  }

  @media (min-width: $screen-md-min) {

    &:after {
      position: absolute;
      top: 206px;
      bottom: -40px;
      width: 1px;
      content: '';
      background: $post-modern-timeline-sm-border-color;
    }

    .post-author {
      padding: 0;
    }

    .post-author-img {
      margin-top: 23px;
      width: 100px;
      height: 100px;

    }

    .post-author {
      position: absolute;
      top: 0;
      margin-top: 0;

      &:after {
        content: '';
        position: absolute;
        top: 52%;
        margin-top: 0;
        width: 52px;
        height: 1px;
        background: $post-modern-timeline-sm-border-color;
      }

      &:hover {
        .post-author-name {
          visibility: visible;
          opacity: 1;
        }
      }

      @media (min-width: $screen-xl) {
        &:after {
          width: 110px;
        }
      }
    }

    &:first-child {
      .post-author:before {
        display: none;
      }
    }

    &:last-child {
      &:after {
        display: none;
      }
    }
  }

  &-left {
    @media (min-width: $screen-md-min) {
      margin-left: 210px;

      &:before,
      &:after {
        left: -101px;
      }

      .post-author {
        right: 100%;
        margin-right: 52px;

        &:before {
          left: 22px;
        }

        &:after {
          left: 100%;
          margin-left: 0;
        }
      }

      .post-author-name {
        right: auto;
        left: 50%;
        margin-top: 13px;
        margin-right: 0;

        &:after {
          content: '';
          position: absolute;
          top: 50%;
          margin-top: -4px;
          left: 100%;
        }
      }
    }
    @media (min-width: $screen-lg-min) {
      float: right;
      .post-author-name {
        right: 100%;
        margin-top: 13px;
        margin-right: 0;
        left: auto;
      }
    }

    @media (min-width: $screen-xl) {
      float: right;

      &:before,
      &:after { left: -161px; }

      .post-author { margin-right: 110px; }
    }
  }
}

.post-modern-timeline-date {
  display: block;
  margin: $post-modern-timeline-date-offsets;
  font-size: $post-modern-timeline-date-size;
  font-weight: $post-modern-timeline-date-weight;
  overflow: hidden;

  time {
    position: relative;

    &:after,
    &:before {
      content: '';
      position: absolute;
      top: 50%;
      height: 1px;
      width: 2000px;
      background: $gray;
    }

    &:before {
      right: 100%;
      margin-right: 30px;
    }

    &:after {
      left: 100%;
      margin-left: 30px;
    }
  }

  @media (min-width: $screen-md-min) {
    time:before,
    time:after {
      display: none;
    }
  }
}

.post-custom {
  display: flex;
  align-items: flex-end;
  overflow: hidden;
  position: relative;
  height: 300px;
  padding: 20px 25px;

  .post-media {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: -1;

    width: calc(100% + 50px);
    transition: .3s;

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    &:before, &:after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      transition: .3s;
    }

    &:before { background: transparent; }
    &:after {
      background: linear-gradient(to top, black, transparent);
      opacity: 0.7;
    }
  }
  .post-body {
    overflow: hidden;
    color: $gray-light;

    .list-dotted {
      > li:not(:last-child):after { background: $gray-light; }
    }
  }

  .post-title {
    color: $secondary;
    font-weight: 700;

    a {
      color: $secondary;
      &:hover { color: darken($secondary, 10%); }
    }
  }

  .author {
    a {
      color: inherit;
      &:hover { color: $secondary; }
    }
  }

  .post-description {
    display: none;
    line-height: $line-height-base;
    transition: 0.35s cubic-bezier(0.76, 0.89, 0.66, 0.82);
    overflow: hidden;
    will-change: line-height;

    p {
      transform: translateY(0);
      opacity: 1;
      transition: 0.4s opacity, 0.4s transform;
      transition-delay: .2s;
    }
  }

  * + .post-title {
    margin-top: 16px;
  }

  .desktop & {
    align-items: flex-end;

    .post-media {
      left: -50px;

      &:before { background: transparent; }
      &:after { background: linear-gradient(to top, black, transparent); }
    }

    .post-description {
      display: block;
      line-height: 0;

      p {
        transform: translateY(30px);
        opacity: 0;
        transition-delay: 0s;
      }
    }

    &:hover {
      .post-media {
        left: 0;
        &:before { background: rgba($primary-dark, .8); }
        &:after { opacity: 0; }
      }

      .post-description {
        line-height: $line-height-base;

        p {
          transform: translateY(0);
          opacity: 1;
          transition-delay: .2s;
        }
      }
    }
  }

  @media (min-width: $screen-lg) { height: 480px; }
  @media (min-width: $screen-xl) { padding: 38px 45px; }
}

.post-single-body {
  .post-heading { max-width: 630px; }
  img { width: 100%; }

  .social-section {
    .icon {
      color: inherit;
      &:hover { color: $link-hover-color; }
    }
  }

  .quote-default {
    max-width: 750px;

    .quote-meta { margin-top: 29px; }
  }

  * + .post-meta { margin-top: 19px; }
  * + img { margin-top: 31px; }
  * + .quote-default { margin-top: 36px; }
  * + .decorative { margin-top: 40px; }
  * + .social-section { margin-top: 41px; }
  .quote-default + img { margin-top: 44px; }

  @media (min-width: $screen-sm) {
    .quote-default {
      padding-left: 50px;
      max-width: 750px;
    }
  }

  @media (min-width: $screen-xl) {
    .quote-default {
      padding-left: 103px;

      .quote-meta { margin-top: 29px; }
    }
  }
}

.box-comment {
  a {
    font-weight: 700;
  }
  .comment-meta,
  .author-reply {
    color: $gray-lighter;
    font-style: italic;
  }

  .author-reply {
    .icon {
      padding-right: 9px;
      font-style: normal;
    }
  }
  .comment { margin-top: 16px; }

  &.box-comment-reply { padding-left: 20px; }

  @media (min-width: $screen-sm) {
    &.box-comment-reply { padding-left: 49px; }
  }
}

.section-grid-post, .section-masonry-post, .post-modern-section {
  .more-button, & + .post-modern-button .more-button { margin-top: 40px; }

  @media (min-width: $screen-lg) {
    .more-button { margin-top: 85px; }
    & + .post-modern-button .more-button { margin-top: 60px; }
  }
}

.post-modern-button {
  max-width: $post-width;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: $screen-md) {
    max-width: $post-width;
    margin-left: 210px;
  }

  @media (min-width: $screen-xl) {
    margin-left: auto;
    margin-right: 0;
  }
}

// Tags
.tag {
  display: inline-block;
  padding: 8px 10px;
  border: 1px solid $secondary;
  color: $secondary;
  background: transparent;

  &:hover {
    color: $gray-darker;
    background: $secondary;
  }
}