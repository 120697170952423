/*
*
* Typography
* --------------------------------------------------
*/

p { margin: 0;}

.quote {
  padding: 0;
  margin: 0;
  font-size: inherit;
  border-left: none;

  q {
    &:before, &:after { content: none; }
  }

  cite {
    font-style: normal;
    font-weight: 700;
  }

  .quote-meta {
    display: inline-block;
    margin-top: 20px;
    text-align: left;
  }

  small {
    margin-top: 6px;
    font-size: 12px;
    line-height: 16px;
    color: $gray-lighter;

    &:before { display: none; }
  }

  .context-dark &{
    q { color: $white; }
  }
}

.quote-primary {
  cite { color: $primary; }
  .icon-quote { color: $primary; }
}

.quote-secondary {
  cite { color: $secondary; }
  .icon-quote { color: $secondary; }
}

.quote-default {
  $font-size-icon: 36px;
  $size-icon: 36px;

  .icon-quote {
    font-size: $font-size-icon;
    min-width: $size-icon;
    height: $size-icon;
    line-height: $size-icon;
  }

  q {
    font-size: 22px;
    line-height: 1.4;
    font-style: italic;
    color: $gray-dark;
  }

  * + p { margin-top: 15px; }

  @media (min-width: $screen-xl) {
    .quote-meta { margin-top: 40px; }
    q {
      font-size: $font-size-h4;
      line-height: $font-lh-h4;
    }
    * + p { margin-top: 27px; }
  }
}

.quote-custom {
  .quote-body {
    position: relative;
    margin-top: 28px;
    padding: 21px 20px 18px 30px;
    background: $white;

    &:before {
      left: 30px;
      top: 0;
      transform: translate(-50%, -50%) rotate(45deg);

      content: "";
      position: absolute;
      width: 10px;
      height: 10px;
      background: inherit;
    }
  }

  .context-dark &{
    small { color: $gray-lighter; }
    q { color: $gray-dark; }
  }
}

.quote-light {
  padding-right: 15px;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;

  .quote-body {
    position: relative;
    margin-top: 20px;
  }

  .icon-quote {
    position: absolute;
    top: -20px;
    right: 0;
    font-size: 60px;
    width: 60px;
    height: 60px;
    line-height: 60px;
    z-index: -1;
    color: $gray-lighter-1;
  }

  @media (min-width: $screen-xl) {
    padding-right: 65px;
    max-width: 560px;

    .icon-quote {
      top: -30px;
    }
  }
}

.mark {
  background-color: $primary;
  color: $white;
}

.text-extra-large {
  font-size: 60px;
  line-height: 0.9;

  @media (min-width: $screen-sm) {
    font-size: 150px;
  }

  @media (min-width: $screen-md) {
    font-size: 200px;
  }
}