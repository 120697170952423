@charset "UTF-8";

/*
*
* Trunk version 1.1.2
*
*/

/**
* Template Style
*
* [Table of contents]
*   1. Custom styles
*     1.1 Main Styles
*     1.2 Typography
*     1.3 Backgrounds
*     1.4 Colors
*     1.5 Main layout
*
*   2. Components
*     2.1 Icons
*     2.2 Buttons
*     2.3 Forms
*     2.4 Tables
*     2.5 Lists
*     2.6 Post
*     2.7 Thumbnail
*     2.8 Tooltip
*     2.9 Snackbars
*     2.10 Navigation
*     2.11 Preloader
*     2.12 Breadcrumbs
*     2.13 Panel custom
*     2.14 Pagination custom
*
*   3. Helpers
*     3.1 Text-alignment
*     3.2 Text-styling
*     3.3 Visibility responsive
*     3.4 Groups
*     3.5 Context Styling
*     3.6 Sections
*     3.7 Offsets
*
*   4. Modules
*     4.1 Flex grid
*     4.2 Unit-responsive
*
*   5 Plugins
*     5.1 Animate
*     5.2 Isotope
*     5.3 Owl Carousel
*     5.4 RD Navbar
*     5.5 RD Parallax
*     5.6 RD Google-Map
*     5.7 RD Search
*     5.8 To top
*     5.9 Tabs
*     5.10 Photoswipe
*     5.11 Progress-bars
*     5.12 Counter
*     5.13 jquery-circle-progress
*     5.14 Timecircles
*     5.15 Swiper
*     5.16 C3 Charts
*
*   6. Fonts
*     6.1 FontAwesome
*     6.2 MDI
**/


// Import variables
@import "variables-custom";

// Import mixins
@import "mixins";


/*
*
* Main Styles
* ==================================================
*/

// Remove leading spacing of element
html {
  *:first-child {
    margin-top: 0;
  }

  *:last-child {
    margin-bottom: 0;
  }
}


// Body

body {
  font-family: $font-family-base;
  font-size: $font-size-base;
  font-weight: $font-weight-base;
  line-height: $line-height-base;
  color: $text-color;
  background-color: $body-bg;
  margin: 0;
}


// Links

a {
  color: $link-color;
  text-decoration: none;
  transition: .3s;

  &:hover,
  &:focus {
    color: $link-hover-color;
    text-decoration: $link-hover-decoration;
    outline: none;
  }

  &[href*='callto'],
  &[href*='mailto'] {
    white-space: nowrap;
  }

  &.link-default {
    color: $text-color;
    &:hover { color: $link-color; }
  }

  &.link-secondary {
    color: $secondary;
    &:hover { color: $link-color; }
  }

  &.link-secondary-darker {
    color: $secondary;
    &:hover { color: darken($secondary, 10%); }
  }

  &.link-gray-dark {
    color: $gray-darker;
    &:hover { color: $link-color; }
  }

  &.link-gray-light {
    color: $gray-lighter;
    &:hover { color: $link-color; }
  }
}

.link-hover{
  color: $link-hover-color;
}

.link { display: inline-block; }

.link-hover-1{
  opacity: .45;
  &:hover { opacity: 1; }
}

.link-press{
  color: $link-press-color;
}

.p a:hover,
.list a:hover{
}


// Images

img {
  display: inline-block;
  max-width: 100%;
  height: auto;
}


/*
*
* Typography
* ==================================================
*/

h1, h2, h3, h4, h5, h6, #{headings()} {
  color: $headings-color;
}

#{headings()} {
  margin-top: 0;
  margin-bottom: 0;

  font-weight: $headings-font-weight;
  color: $headings-color;

  a:hover { color: darken($primary, 15%); }
}

h1,
.heading-1 {
  font-size: 40px;
  line-height: 1.1;

  @media (min-width: $screen-lg-min) {
    line-height: $font-lh-h1;
    font-size: $font-size-h1;
  }
}

h2,
.heading-2 {
  font-size: 36px;
  line-height: 1.1;

  @media (min-width: $screen-lg-min) {
    line-height: $font-lh-h2;
    font-size: $font-size-h2;
  }
}

h3,
.heading-3 {
  font-size: 30px;
  line-height: 1.2;

  @media (min-width: $screen-lg-min) {
    line-height: $font-lh-h3;
    font-size: $font-size-h3;
  }
}

h4,
.heading-4 {
  font-size: 26px;
  line-height: 1.3;

  @media (min-width: $screen-lg-min) {
    line-height: $font-lh-h4;
    font-size: $font-size-h4;
  }
}

h5,
.heading-5 {
  font-size: 22px;
  line-height: 1.3;

  @media (min-width: $screen-lg-min) {
    line-height: $font-lh-h5;
    font-size: $font-size-h5;
  }
}

h6,
.heading-6 {
  font-size: 18px;
  line-height: 1.4;

  @media (min-width: $screen-lg-min) {
    line-height: $font-lh-h6;
    font-size: $font-size-h6;
  }
}


// Big text
.big {
  font-size: $font-size-large;
}


// Small text
small,
.small {
  display: block;
  font-size: $font-size-small;
}


// Divider
// -------------------------

hr{
  margin-top: 0;
  margin-bottom: 0;
  border-top: 1px solid $gray-lighter;
}

.divider {
  display: block;
  font-size: 0;
  line-height: 0;

  &:before {
    content: "";
    display: inline-block;
    margin-top: 0;
    margin-bottom: 0;
  }

  &-1 {
    &:before {
      width: 42px;
      border-top: 6px solid $secondary;
    }
  }

  &-2 {
    &:before { width: 130px; }
  }

  &-3 {
    &:before {
      width: 100%;
      border-top: 1px solid $gray-dark;
    }
  }

  &-4 {
    &:before {
      width: 99px;
      border-top: 4px solid $gray-light;
    }
  }
}

.decorative-divider {
  display: block;
  font-size: 0;
  line-height: 0;

  &:before {
    content: "";
    display: inline-block;
    width: 30px;
    margin-top: 0;
    margin-bottom: 0;
    border-top: 3px solid $secondary;
  }
}

// Construct blocks
.relative-block { position: relative; }
.inline-block { display: inline-block; }
.block-centered {
  margin-left: auto;
  margin-right: auto;
}

/*
*
* Backgrounds
* ==================================================
*/
// bg-behaviour() off padding top if two section with same bg that are beside

.bg-white {
  @include bg-behaviour($white);
}

.bg-gray {
  @include bg-behaviour($gray-lightest);
}

.bg-primary {
  @include bg-behaviour($primary);

  a {
    color: inherit;

    &:hover { color: $primary; } 
  }

  // Change color of primary button on primary bg
  .btn-primary {
    @include btn-variant($primary, $white, $white, $primary, darken($white, 5%), darken($white, 5%));
  }
}

.bg-primary-dark {
  @include bg-behaviour($primary-dark);

  a {
    color: $secondary;
    &:hover { color: $white; }

    &.link-default {
      color: $gray-lighter;
      &:hover { color: $secondary; }
    }

    &.link-secondary {
      color: $secondary;
      &:hover { color: $white; }
    }
  }

  p { color: $gray-lighter; }
}

%bg-image {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

%bg-image-count {
  background-color: $gray-darker;
  @media (max-width: $screen-md-max) {
    position: relative;
    z-index: 0;

    > * {
      position: relative;
      z-index: 1;
    }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: rgba($gray-base, .4);
    }
  }
}

.bg-image-breadcrumbs {
  @extend %bg-image-count;
  background-image: url('../images/bg-image-breadcrumbs.jpg');
}

.bg-image-breadcrumbs-1 {
  @extend %bg-image;
  background-image: url('../images/bg-image-breadcrumbs-1.jpg');
}

.bg-image-3 {
  @extend %bg-image;
  background-image: url('../images/bg-image-3.jpg');
}

.bg-image-2 {
  @extend %bg-image;
  background-image: url('../images/bg-image-2.jpg');
}

.bg-image-1 {
  @extend %bg-image;
  background-image: url('../images/index-bg-1-1920x743.jpg');
}

html.desktop {
  .bg-fixed {
    @media (min-width: $screen-md-min) {
      background-attachment: fixed;
    }
  }
}


/*
*
* Colors
* ==================================================
*/

.page {
  @include text-variant('.text-gray-base', $gray-base, $primary);
  @include text-variant('.text-gray-darker', $gray-darker, $primary);
  @include text-variant('.text-gray-dark', $gray-dark, $primary);
  @include text-variant('.text-gray', $gray, $primary);
  @include text-variant('.text-gray-light', $gray-light, $primary);
  @include text-variant('.text-gray-lighter', $gray-lighter, $primary);
  @include text-variant('.text-primary', $primary, darken($primary, 10%));
  @include text-variant('.text-secondary', $secondary, darken($secondary, 10%));
  @include text-variant('.text-white', $white, darken($white, 10%));
}


/*
*
* Main layout
* ==================================================
*/

// Page
//

.page {
  overflow: hidden;
  min-height: 100vh;
}


// Page header
//

.page-header {
  //Kill bootstrap style
  padding: 0;
  margin: 0;
  border-bottom: none;

  position: relative;
  z-index: $zindex-page-header;
}


// Page footer
//

.page-footer-default {
  padding-top: 10px;
  padding-bottom: 10px;

  .footer-top {
    padding-top: 40px;
    padding-bottom: 20px;
  }

  .footer-bottom {
    padding-top: 20px;
    padding-bottom: 10px;
  }

  @media (min-width: $screen-lg) {
    .decorative {
      padding-left: 0;

      &-title {
        &:before { display: none }
      }
    }
  }

  @media (min-width: $screen-xl) {

    .footer-top {
      padding-top: 87px;
      padding-bottom: 50px;
    }
    .footer-bottom {
      padding-top: 36px;
      padding-bottom: 17px;
    }

    .decorative {
      padding-left: 31px;

      &-title {
        &:before { display: block; }
      }
    }
  }
}

.page-footer-dark {
  padding-top: 60px;
  padding-bottom: 20px;

  @media (min-width: $screen-lg) {
    .rd-mailform-inline {
      .button { padding: 7px; }
    }
  }

  @media (min-width: $screen-xl) {
    padding-top: 91px;
    padding-bottom: 80px;

    .rd-mailform-inline {
      .button { padding: 7px 22px; }
    }
  }
}

.pre-footer-light {
  padding-top: 60px;
  padding-bottom: 50px;

  h5 + * { margin-top: 22px; }

  @media (min-width: $screen-xl) {
    padding-top: 91px;
    padding-bottom: 86px;
  }
}

.page-footer-light {
  padding-top: 25px;
  padding-bottom: 27px;
}


// Components & modules & plugins & helpers & fonts
//

//modal 
.modal-open {
  overflow: auto;
  padding-right: 0 !important;
}
.modal.fade .modal-dialog {
  transform: translate(-50%, -60%);
}
.modal.in .modal-dialog {
  transform: translate(-50%, -50%);
}
.modal {
  .modal-dialog {
    top: 50%;
    left: 50%;
    margin: 10px;
  }
  .modal-body {
    padding: 26px;

  }
  .modal-header {
    padding: 15px 25px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    .modal-title {
      flex: 1;
    }
    .close {
      font-size: 30px;
    }
  }
}
@import "includes";