/*
*
* Icons
* --------------------------------------------------
*/

$font-size-xxs: 18px;
$size-xxs: 18px;

$font-size-xss: 20px;
$size-xss: 20px;

$font-size-xs: 24px;
$size-xs: 24px;

$font-size-sm: 30px;
$size-sm: 30px;

$font-size-md: 40px;
$size-md: 40px;

$font-size-lg: 50px;
$size-lg: 50px;

$font-size-xl: 56px;
$size-xl: 56px;

$font-size-xxl: 72px;
$size-xxl: 72px;

.page {
  // Base styles
  // --------------------------------------------------

  .icon {
    display: inline-block;
    text-align: center;
    font-size: $size-md;
    line-height: $size-md;
    border-radius: 50%;
    vertical-align: middle;

    &:before {}

    &-image {
      position: relative;

      img {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }


  // Alternate icons
  // --------------------------------------------------

  .icon-default {}

  .icon-primary {
    color: $primary;
    .bg-primary &{ color: $white; }
  }

  .icon-image-primary {
    background: $primary;
    .bg-primary &{ color: $secondary; }
  }

  .icon-image-secondary {
    background: $secondary;
    .bg-primary &{ color: $primary; }
  }

  .icon-secondary {
    color: $secondary;
    .bg-secondary &{ color: $white; }
  }

  .icon-white {
    color: $white;
    .bg-white &{ color: $secondary; }
  }

  .icon-gray-lightest {
    color: $gray-lightest;
    .bg-white &{ color: $secondary; }
  }

  .icon-gray-dark {
    color: $gray-dark;
    .bg-gray &{ color: $secondary; }
  }


  // Icon Shapes
  // --------------------------------------------------

  .icon-circle{
    border-radius: 50%;
  }


  // Icon Sizes
  // --------------------------------------------------

  .icon-xxs {
    font-size: $font-size-xxs;
    min-width: $size-xxs;
    height: $size-xxs;
    line-height: $size-xxs;
  }

  .icon-xss {
    font-size: $font-size-xss;
    min-width: $size-xss;
    height: $size-xss;
    line-height: $size-xss;
  }

  .icon-xs {
    font-size: $font-size-xs;
    min-width: $size-xs;
    height: $size-xs;
    line-height: $size-xs;
  }

  .icon-sm {
    font-size: $font-size-sm;
    min-width: $size-sm;
    height: $size-sm;
    line-height: $size-sm;
  }

  .icon-md {
    font-size: $font-size-md;
    min-width: $size-md;
    height: $size-sm;
    line-height: $size-sm;
  }

  .icon-lg {
    font-size: $font-size-lg;
    min-width: $size-lg;
    height: $size-lg;
    line-height: $size-lg;
  }

  .icon-xl {
    font-size: $font-size-xl;
    min-width: $size-xl;
    height: $size-xl;
    line-height: $size-xl;
  }

  .icon-xxl {
    font-size: $font-size-xxl;
    min-width: $size-xxl;
    height: $size-xxl;
    line-height: $size-xxl;
  }

  .icon-image-lg {
    width: 120px;
    height: 120px;
  }
}