/*
*
* Decorative
* --------------------------------------------------
*/

// Decorative title divider
.decorative {
  &-title {
    display: inline-block;
    position: relative;
    font-weight: 700;

    > * { font-weight: 700; }

    &:before {
      content: "";
      position: absolute;
      background-color: $secondary;
      top: 0;
      bottom: 0;
    }
  }

  .decorative-title + .decorative-title { margin-top: 0; }

  &-sm {
    padding-left: 15px;
    .decorative-title {
      &:before {
        left: -15px;
        width: 3px;
      }
    }
  }
  &-md {
    padding-left: 15px;
    .decorative-title {
      &:before {
        left: -15px;
        width: 3px;
      }
    }
  }
  &-lg {
    padding-left: 15px;
    .decorative-title {
      &:before {
        left: -15px;
        width: 6px;
      }
    }
  }


  @media (min-width: $screen-md) {
    &-md {
      padding-left: 31px;
      .decorative-title {
        &:before { left: -31px; }
      }
    }
    &-lg {
      padding-left: 44px;
      .decorative-title {
        &:before { left: -44px; }
      }
    }
  }
}

// Decorative counter container
.decorative-container {
  position: relative;
  padding: 25px 0;

  html & .decorative-cell {
    padding: 25px 0;

    > * {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  @media (min-width: $screen-sm) { padding: 50px 0; }

  @media (min-width: $screen-md) {
    padding: 65px 0;
    .decorative-cell {

      &:before, &:after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        padding: 0;
      }

      &:before { border-right: 1px solid rgba($white, .2); }

      &:last-child:after {
        right: 0;
        border-left: 1px solid rgba($white, .2);
      }

      //.counter-wrap { padding: 0 15px; }
    }
  }

  &-lg {
    @media (min-width: $screen-md) { padding: 97px 0; }
  }
}

// Decorative image wrappers
.image-wrapper-2 {
  @media (min-width: $screen-sm) {
    display: flex;
    align-items: flex-start;

    > * {
      max-width: 33.333%;
      height: auto;
    }
  }

  @media (min-width: $screen-lg) {
    flex-direction: column;
    max-width: 700px;
    margin: auto;

    > * {
      position: relative;
      max-width: 100%;
    }

    > *:nth-child(3n+1) {
      align-self: flex-start;
      margin-top: -99px;
    }

    > *:nth-child(3n+2) {
      align-self: flex-end;
      margin-top: -99px;
    }

    > *:nth-child(3n+3) {
      align-self: center;
      margin-top: -49px;
    }

    > *:first-child {
      margin-top: 0;
    }
  }
}

.image-wrapper-3 {
  display: flex;
  flex-direction: column;

  > * {
    margin-top: 10px;
    box-shadow: $shadow-area-lg;
  }

  @media (min-width: $screen-lg) {
    max-width: 800px;

    > * {
      position: relative;
    }

    > *:nth-child(2n+1) {
      align-self: flex-start;
      margin-top: 0;
    }

    > *:nth-child(2n+2) {
      align-self: flex-end;
      margin-top: -256px;
    }
  }
}

// Custom vertical title
.custom-title {
  display: inline-block;

  & + * { margin-top: 30px; }

  @media (min-width: $screen-sm) {
    position: absolute;
    top: 50%;
    left: 6%;
    transform: translateY(-50%);

    > *:first-child {
      transform: rotate(270deg) translateY(-100%) translateX(-50%);
      transform-origin: 0 0;
    }

    & + * { margin-top: 0; }
  }

  @media (min-width: $screen-md) {
    left: 15px;
  }

  @media (min-width: $screen-lg) {
    left: 30px;
  }

  @media (min-width: $screen-xl) {
    left: 8.333%;
  }
}

// Custom vertical space-between cell
.cell-custom {
  > * + * { margin-top: 30px; }

  @media (min-width: $screen-xl) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &:before {
      display: none !important;
    }

    p { max-width: 240px; }
  }
}

.cell-vertial-align {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &:before { display: none !important; }

  > * + * { margin-top: 20px; }
}

// Custom text block
.text-block-1 {
  @media (min-width: $screen-lg) { max-width: 590px; }
}

/*
****  Boxes
*/

// Vertical box
.box-vertical {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  flex-grow: 1;
  align-items: flex-start;
  justify-content: space-between;
  padding: 20px 20px;
  background: $white;
  color: $text-color;
  transition: .3s;

  &-header {
    font-weight: 700;
    color: $primary;
  }

  & > * {
    transition: none;
    max-width: 100%;
  }

  &-header + &-description { margin-top: 13px; }

  &:hover {
    box-shadow: 0 0 13px 0 rgba(0, 0, 0, 0.09);
    color: $text-color;
  }

  @media (min-width: $screen-sm) { padding: 25px 20px; }
  @media (min-width: $screen-xl) { padding: 45px 48px 40px; }
}

// Price box
.price-box {
  padding: 46px 10px 54px;
  text-align: center;
  transition: .3s;

  .title, .price { font-weight: 700; }
  .price { color: $gray-lighter; }
  .button { padding: 10px 29px }

  * + .title { margin-top: 10px; }
  * + .price { margin-top: 21px; }
  * + .button { margin-top: 24px; }

  box-shadow: $shadow-area-lg;

  @media (min-width: $screen-sm) {
    box-shadow: none;
    &:hover { box-shadow: $shadow-area-lg; }
  }

  @media (min-width: $screen-lg) {
    padding: 46px 10px 54px;
  }
}

// Content box
.box-content {
  background-color: rgba($primary-dark, .9);
  padding: 20px;

  h1 { font-weight: 300; }
  .caption {
    font-weight: 400;
    color: $gray-light;
  }
  h1 + h3 { margin-top: 5px; }

  @media (min-width: $screen-lg) {
    padding: 45px 50px 67px 56px;
  }
}

// Contact box small
.contact-box-small {
  & + & {
    margin-top: 30px;
    padding-top: 30px;
    border-top: 1px solid $gray-lightest;
  }
}