/*
*
* Form styles
* --------------------------------------------------
*/

//== Forms variables
//

$input-height: 54px;

$form-wrap-offset: 15px;

$textarea-default-height:       148px;
$textarea-default-min-height:   $textarea-default-height * 0.8;
$textarea-default-max-height:   $textarea-default-height * 1.7;

$input-color: $gray-dark;
$input-color-placeholder: $input-color;

$input-bg: $gray-lightest;

$input-border: $gray-lightest;
$input-border-radius: 0;

$input-font-size: 16px;
$input-line-height: 24px;
$input-font-weight: 400;

$input-padding-vertical: 12px;
$input-padding-horizontal: 17px;

$error-color: #d9534f;
$success-color: #5cb85c;



.rd-mailform {
  position: relative;
  text-align: left;
}

html .rd-mailform-inline{
  @include display-flex();

  .form-wrap{
    @include flex-grow(1);
  }

  .button{
    margin-top: 0;
    flex: none;
  }

  &:not(.rd-mailform-small) .form-validation {
    margin-top: 5px;
  }
}


// Form input
// -------------------------

.form-input {
  display: block;
  width: 100%;
  height: $input-height;
  padding: $input-padding-vertical $input-padding-horizontal;
  padding-right: 10px;
  font-size: $input-font-size;
  font-weight: $input-font-weight;
  line-height: $input-line-height;
  color: $input-color;
  background-color: $input-bg;
  background-image: none;
  border: 1px solid $input-border;
  border-radius: $input-border-radius;
  -webkit-appearance: none;

  &:focus {
    outline: 0;
  }

  &:-webkit-autofill ~ label,
  &:-webkit-autofill ~ .form-validation {
    color: $gray-base !important;
  }

  .bg-gray & {
    background-color: $white;
    border: 1px solid $white;
  }
}

.form-control {
  box-shadow: none;

  &:focus {
    box-shadow: none;
    outline: none;
    border-color: $input-border;
  }
}

textarea.form-input {
  height: $textarea-default-height;
  min-height: $textarea-default-min-height;
  max-height: $textarea-default-max-height;
  resize: vertical;
}

// Form wrapper
// -------------------------

.form-wrap {
  position: relative;
}

.form-wrap + .form-wrap{
  margin-top: $form-wrap-offset;
}

* + .form-button,
.form-wrap + .button{
  margin-top: 30px;
}


// Form labels
// -------------------------

.form-label {
  position: absolute;
  top: $input-height / 2;
  left: $input-padding-horizontal;

  font-size: $input-font-size;
  font-weight: $input-font-weight;
  line-height: $input-line-height;
  color: $input-color-placeholder;
  pointer-events: none;
  z-index: 9;
  transition: .3s;
  transform: translateY(-50%);

  &.focus {
    opacity: 0;
  }

  &.auto-fill {
    color: $input-color;
  }
}

.form-label-outside {
  position: static;
  &, &.focus, &.auto-fill {
    opacity: 1;
    transform: none;
    color: $input-color-placeholder;
    font-size: $input-font-size - 4px;
  }

  & + .form-input + .form-validation{ top: 32px; }
}

label { margin-bottom: 2px; }


// Form validation
// -------------------------

.form-validation {
  position: absolute;
  right: 10px;
  top: 1px;
  font-size: 11px;
  line-height: 11px;
  color: $error-color;
  margin-top: 1px;
  transition: .3s;
  z-index: 11;
}

.form-validation-left {
  .form-validation {
    right: auto;
    top: 100%;
    left: 0;
  }
}


// Form output
// -------------------------

#form-output-global {
  position: fixed;
  bottom: 30px;
  left: 15px;
  visibility: hidden;
  transform: translateX(-500px);
  transition: .3s all ease;
  z-index: 9999999;

  &.active {
    transform: translateX(0);
    visibility: visible;
  }

  @media (min-width: $screen-xs) {
    left: 30px;
  }
}

.form-output {
  position: absolute;
  top: 100%;
  left: 0;
  font-size: 14px;
  line-height: 1.5;
  margin-top: 2px;
  transition: .3s;
  opacity: 0;
  visibility: hidden;

  &.active {
    opacity: 1;
    visibility: visible;
  }

  &.error {
    color: $error-color;
  }

  &.success {
    color: $success-color;
  }
}


// Radio and Checkbox Custom
// -------------------------


// Base Styles
//

.radio .radio-custom,
.radio-inline .radio-custom,
.checkbox .checkbox-custom,
.checkbox-inline .checkbox-custom {
  opacity: 0;

  &, &-dummy {
    position: absolute;
    width: 14px;
    height: 14px;
    margin-left: -20px;
    margin-top: 5px;
    outline: none;
    cursor: pointer;
  }

  &-dummy {
    pointer-events: none;
    background: $gray-lighter;
    box-shadow: inset 1px 1px 1px rgba(0, 0, 0, 0.15);

    &:after {
      position: absolute;
      opacity: 0;
    }
  }

  &:focus {
    outline: none;
  }
}

.radio-custom:checked + .radio-custom-dummy:after,
.checkbox-custom:checked + .checkbox-custom-dummy:after {
  opacity: 1;
}


// Custom Radio Styles
//

.radio,
.radio-inline {
  .radio-custom-dummy {
    border-radius: 50%;

    &:after {
      content: '';
      top: 3px;
      right: 3px;
      bottom: 3px;
      left: 3px;
      background: $gray-darker;
      border-radius: 50%;
    }
  }
}


// Custom Checkbox Styles
//

.checkbox,
.checkbox-inline {
  padding-left: 20px;
  .checkbox-custom-dummy {
    pointer-events: none;
    border-radius: 3px;
    margin-left: 0;
    left: 0;

    &:after {
      content: '\f222';
      font-family: "Material Design Icons";
      font-size: 20px;
      line-height: 10px;
      position: absolute;
      top: 0;
      left: 0;
      color: $gray-darker;
    }
  }
}

// Small form styles

.rd-mailform-small {

  //== Forms variables
  //

  $input-height-small: 42px;

  $input-font-size-small: 14px;
  $input-line-height-small: 18px;

  $input-padding-vertical: 12px;
  $input-padding-horizontal-small: 19px;

  // Form input
  // -------------------------

  .form-input {
    height: $input-height-small;
    padding: $input-padding-vertical $input-padding-horizontal-small;
    padding-right: 10px;
    font-size: $input-font-size-small;
    line-height: $input-line-height-small;
  }


  // Form labels
  // -------------------------

  .form-label {
    position: absolute;
    top: $input-height-small / 2;
    left: $input-padding-horizontal-small;

    font-size: $input-font-size-small;
  }

  .form-label-outside {
    @media (min-width: $screen-sm-min) {
      &, &.focus, &.auto-fill {
        font-size: $input-font-size - 4px;
      }
    }
  }
}