/*
*
* Owl Carousel
* --------------------------------------------------
*/

.owl-carousel .animated {
  -webkit-animation-duration: 1000ms;
  animation-duration: 1000ms;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.owl-carousel .owl-animated-in {
  z-index: 0;
}

.owl-carousel .owl-animated-out {
  z-index: 1;
}

.owl-carousel .fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

/* 
 * 	Owl Carousel - Auto Height Plugin
 */
.owl-height {
  transition: height 500ms ease-in-out;
}

/* 
 *  Core Owl Carousel CSS File
 */
.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  /* position relative and z-index fix webkit rendering fonts issue */
  position: relative;
  z-index: 1;
}

.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
}

.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  /* fix for flashing background */
  -webkit-transform: translate3d(0px, 0px, 0px);
}

.owl-carousel .owl-controls .owl-nav .owl-prev,
.owl-carousel .owl-controls .owl-nav .owl-next,
.owl-carousel .owl-controls .owl-dot {
  cursor: pointer;
  user-select: none;
}

.owl-carousel.owl-loaded {
  display: block;
}

.owl-carousel.owl-loading {
  opacity: 0;
  display: block;
}

.owl-carousel.owl-hidden {
  opacity: 0;
}

.owl-carousel .owl-refresh .owl-item {
  display: none;
}

.owl-carousel .owl-item {
  position: relative;
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

.owl-carousel .owl-grab {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab;
}

.owl-carousel.owl-rtl {
  direction: rtl;
}

.owl-carousel.owl-rtl .owl-item {
  float: right;
}

/* No Js */
.no-js .owl-carousel {
  display: block;
}

/* 
 * 	Owl Carousel - Lazy Load Plugin
 */
.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  transition: opacity 400ms ease;
}

/* 
 * 	Owl Carousel - Video Plugin
 */
.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000;
}

.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  font: 400 40px/80px 'FontAwesome';
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  transition: scale 100ms ease;
  &:before {
    content: '\f144';
  }
}

.owl-carousel .owl-video-play-icon:hover {
  -webkit-transform: scale(1.3);
  transform: scale(1.3);
}

.owl-carousel .owl-video-playing .owl-video-tn,
.owl-carousel .owl-video-playing .owl-video-play-icon {
  display: none;
}

.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: opacity 400ms ease;
}

.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
}

.owl-item { width: 100%; }

/*
 * Owl Navigation
 */

.owl-nav {

}

.owl-prev, .owl-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font: 400 46px/50px 'FontAwesome';
  color: $gray-base;
  transition: .3s;

  &:hover {
    color: $primary;
  }

  .context-dark & {
    color: $white;

    &:hover { color: $primary; }
  }
}

.owl-prev {
  left: 0;

  &:before {
    content: '\f104';
  }
}

.owl-next {
  right: 0;

  &:before {
    content: '\f105';
  }
}

/*
 * Owl Pagination
 */

.owl-dots {
  text-align: center;
  margin-top: 10px;
}

.owl-dot {
  position: relative;

  display: inline-block;
  width: 10px;
  height: 10px;
  border: 2px solid $gray-base;
  border-radius: 50%;

  text-align: center;
  outline: none;
  cursor: pointer;
  background-color: transparent;
  transition: .2s;

  &:hover,
  &:focus {
    background-color: $gray-base;
  }

  &:only-child {
    display: none;
  }

  &.active {
    background-color: $gray-base;
  }

  & + &{
    margin-left: 10px;
  }
}

.owl-carousel.owl-carousel-light-dots{
  .owl-dot{
    border-color: $white;

    &:hover,
    &:focus {
      background-color: $white;
    }

    &.active {
      background-color: $white;
    }
  }
}

.owl-carousel-numbered{

  .owl-dots { counter-reset: owl-dot; }

  .owl-dot{
    position: relative;
    margin: 0 3px;
    padding-bottom: 0;
    width: auto;
    height: auto;
    border-radius: 0;
    font-weight: 700;
    color: rgba($gray-darker, .5);
    background: transparent;
    border: none;

    &:before {
      content: counter(owl-dot, decimal-leading-zero);
      counter-increment: owl-dot;
    }

    &:after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      border-bottom: 1px solid transparent;
      transition: .3s;
      pointer-events: none;
    }

    &.active {
      color: $gray-darker;
      &:after { border-bottom: 1px solid $primary; }
    }

    &:hover,
    &:focus { color: $primary; }
  }

  @media (min-width: $screen-sm) {
    .owl-dots {
      margin-top: 0;
      position: absolute;
      top: 50%;
      right: 30px;
      transform: translateY(-50%);
    }

    .owl-dot{
      display: block;
      margin: 15px 0;
      padding-bottom: 4px;

      &:after { width: 50vw; }
    }

    &-custom {
      width: calc(100% - 80px);

      .owl-dots { right: -50px; }
    }
  }

  @media (min-width: $screen-xl) {
    .owl-dots {
      right: 152px;
    }

    .owl-dot{ margin: 32px 0; }

    &-custom {
      width: calc(100% - 24%);
      margin-left: 8%;

      .owl-dots { right: -11%; }
    }
  }

  .context-dark & {
    .owl-dot{
      color: rgba($white, .5);

      &.active { color: $white; }
    }
  }
}

.owl-carousel-numbered-secondary {
  .owl-dot{
    &.active {
      &:after { border-bottom: 1px solid $secondary; }
    }
    &:hover,
    &:focus {
      color: $secondary;
    }
  }
}

.owl-carousel-var-2, .owl-carousel-var-3 {
  .owl-dots {
    margin-top: 0;
    .owl-dot { margin-top: 30px; }
  }

  .owl-dot {
    background: $gray-dark-1;
    border: none;

    &.active {
      background: $secondary;
      transform: scale(1.6);
    }

    &:hover {
      background: $secondary;
    }
  }

  .owl-dot + .owl-dot { margin-left: 20px; }

  @media (min-width: $screen-xl) {
    .owl-dots {
      .owl-dot { margin-top: 54px; }
    }
    .owl-dot + .owl-dot { margin-left: 30px; }
  }
}

.owl-carousel-var-3 {
  .owl-dot { background: $gray-light; }
}